import React, { useState, useEffect } from 'react';
import { NavLink, Outlet } from "react-router-dom";
import { connect } from "react-redux";
import BN from "bn.js";
import EventBus from 'eventing-bus';
import { web3 } from "../../store/web3";
import { env, networkType } from '../../store/config';
import { connectMetamask, getNetworkSymbol } from "../../store/walletConnect";
import { logout, getUser, setAddress, getNonce, login, setNonce } from '../../store/actions/Auth';
import { GasMineAddress, GasMineContract } from "../../store/contract"

import '../../app/HBurnMarketplace/index.css';

const Footer = (props) => {
  let [login, setLogin] = useState(false);
  let [isSign, setIsSign] = useState(false);

  const handleWalletChanges = () => {
    let network = ''
    if (env == 'production') {
      network = 'avalanche';
    } else {
      network = 'skale';
    }
    console.log("*** network", network);

    if (network) {
      connectMetamask(network, networkType)
        .then(async ({ address }) => {
          if (address && !isSign) {
            console.log("*** 0");
            let chain = await web3.eth.getChainId();
            chain = web3.utils.hexToNumber(chain);
            chain = Number(chain);
            let balanceWei = await web3.eth.getBalance(address);
            console.log("*** address :: ", address);
            console.log("*** chain :: ", chain);
            console.log("*** balanceWei :: ", balanceWei);


            // if (balanceWei <= 10000000000000) {
            //   console.log("*** 1");
            //   let to = '';
            //   if (chain == 37084624) {
            //     to = "0x000E9c53C4e2e21F5063f2e232d0AA907318dccb";
            //   } else if (chain == 1482601649) {
            //     to = "0x5a6869ef5b81DCb58EBF51b8F893c31f5AFE3Fa8";
            //   }
            //   console.log("*** to :: ", to);
            //   let nonce = await web3.eth.getTransactionCount(address);
            //   let gas = 100000;
            //   console.log("*** 2");
            //   const mineFreeGasResult = await mineGasForTransaction(nonce, gas, address);
            //   // const mineFreeGasResult = (10000000000000).toString();
            //   console.log("*** mineFreeGasResult :: ", mineFreeGasResult);
            //   console.log("*** mineFreeGasResult :: ", typeof mineFreeGasResult);
            //   console.log("*** 3");
            //   const functionSignature = "0x0c11dedd";

            //   const request = {
            //     from: address,
            //     to,
            //     data: `${functionSignature}000000000000000000000000${address.substring(2)}`,
            //     nonce,
            //     gasPrice: mineFreeGasResult
            //   }
            //   console.log("*** 4");
            //   const tx = await web3.eth.sendTransaction(request);
            //   console.log("*** tx :: ", tx);
            //   console.log("*** 5");
            // }

            props.setAddress({ publicAddress: address, chain });
            props.getUser({ publicAddress: address });
            props.getNonce({ publicAddress: address, chain });
            setIsSign(true);
            //   return EventBus.publish("success", `wallet connect successfuly`); 
          }
        })
        .catch((error) => {
          // return EventBus.publish("error", `failed to connect wallet: ${error}`);
          return EventBus.publish("error", `Could not establish RPC connection!`);
        });
    }
  };

  // const callGasMineAPI = async () => {
  //   // 0x000E9c53C4e2e21F5063f2e232d0AA907318dccb testnet address
  //   // 0x5a6869ef5b81DCb58EBF51b8F893c31f5AFE3Fa8 mainnet address
  //   if (props.publicAddress == null || props.publicAddress == undefined) {
  //     EventBus.publish("error", `Wallet connection pending!`);
  //     return;
  //   }
  //   let deployer = (await web3.currentProvider.enable())[0];
  //   const balanceWei = await web3.eth.getBalance(deployer);
  //   if (balanceWei < 5000000000000) {
  //     await web3.eth
  //       .sendTransaction({
  //         from: deployer,
  //         to: GasMineAddress,
  //         gas: 100000,
  //         data: GasMineContract.methods
  //           .pay(deployer)
  //           .encodeABI(),
  //       })
  //       .on('transactionHash', hash => {
  //         console.log(`************** gas mining hash = ${hash}`);
  //       })
  //       .on('receipt', async receipt => {
  //       });
  //   }
  // }

  // const callGasMineContract = async () => {
  //   // 0x000E9c53C4e2e21F5063f2e232d0AA907318dccb testnet address
  //   // 0x5a6869ef5b81DCb58EBF51b8F893c31f5AFE3Fa8 mainnet address
  //   if (props.publicAddress == null || props.publicAddress == undefined) {
  //     EventBus.publish("error", `Wallet connection pending!`);
  //     return;
  //   }
  //   let deployer = (await web3.currentProvider.enable())[0];
  //   const balanceWei = await web3.eth.getBalance(deployer);
  //   if (balanceWei < 5000000000000) {
  //     await web3.eth
  //       .sendTransaction({
  //         from: deployer,
  //         to: GasMineAddress,
  //         gas: 100000,
  //         data: GasMineContract.methods
  //           .pay(deployer)
  //           .encodeABI(),
  //       })
  //       .on('transactionHash', hash => {
  //         console.log(`************** gas mining hash = ${hash}`);
  //       })
  //       .on('receipt', async receipt => {
  //       });
  //   }
  // }

  const mineGasForTransaction = async (nonce, gas, address) => {
    console.log("*** 2.1");
    if (gas === undefined || address === undefined || nonce === undefined) {
      throw new Error("Not enough fields for mining gas (from, nonce)")
    }
    console.log("*** 2.2");
    nonce = web3.utils.isHex(nonce) ? web3.utils.hexToNumber(nonce) : nonce;
    console.log("*** nonce :: ", nonce);
    console.log("*** 2.3");
    gas = web3.utils.isHex(gas) ? web3.utils.hexToNumber(gas) : gas;
    console.log("*** gas :: ", gas);
    console.log("*** 2.4");
    let gasPrice = await mineFreeGas(gas, address, nonce, web3);
    console.log("*** gasPrice :: ", gasPrice);
    console.log("*** 2.5");
    return gasPrice;
  }

  const mineFreeGas = async (gasAmount, address, nonce, web3) => {
    const DIFFICULTY = new BN(1);
    console.log('Mining free gas: ', gasAmount);
    let nonceHash = new BN(web3.utils.soliditySha3(nonce).slice(2), 16)
    let addressHash = new BN(web3.utils.soliditySha3(address).slice(2), 16)
    let nonceAddressXOR = nonceHash.xor(addressHash)
    let maxNumber = new BN(2).pow(new BN(256)).sub(new BN(1));
    let divConstant = maxNumber.div(DIFFICULTY);
    let candidate;
    while (true) {
      let randomHex = '';
      for (let i = 0; i < 32; i++) {
        randomHex += Math.floor(Math.random() * 256).toString(16).padStart(2, '0');
      }
      candidate = new BN(randomHex, 16);
      let candidateHash = new BN(web3.utils.soliditySha3(candidate).slice(2), 16);
      let resultHash = nonceAddressXOR.xor(candidateHash);
      let externalGas = divConstant.div(resultHash).toNumber();
      if (externalGas >= gasAmount) {
        break;
      }
    }
    return candidate.toString();
  }

  const loginWallet = async (nonce) => {
    try {
      const accounts = await web3.eth.getAccounts();
      const address = accounts[0];
      if (address) {
        let chain = await web3.eth.getChainId();
        chain = web3.utils.hexToNumber(chain);
        chain = Number(chain);
        const signature = await web3.eth.personal.sign(`HBurn marketplace signature ${nonce}`, address);
        if (address && signature) props.login({ address, signature, nonce, chain })
      }
    } catch (error) {
      return EventBus.publish("error", `failed to create signature: ${error}`);
    }
  }

  useEffect(() => {
    if (props.nonce && isSign) loginWallet(props.nonce);
  }, [props.nonce])

  return (
    <>
      <button className="footer common-btn" onClick={() => handleWalletChanges()}>{props?.publicAddress ? props.publicAddress.slice(0, 6) + "..." + props.publicAddress.slice(38, 42) : "Connect Wallet"}<img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/btn-arrow.png" alt="" /></button>

      <footer>
        <div className="container">
          <div className="inner">
            <div className="copyright">
              <p>Powered by <a target="_blank" href="https://hashverse.solutions" style={{ color: "white" }}>Hashverse Solutions</a></p>
            </div>

            <ul className='social-icons'>
              <li><a target="_blank" href="https://youtube.com/@hburn-hederagon?si=eDI-aEpfJQOWLwmK"><img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/YoutubeLogo.svg" alt="" /></a></li>
              <li><a target="_blank" href="https://discord.com/invite/6FpchyjBkP"><img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/DiscordLogo.svg" alt="" /></a></li>
              <li><a target="_blank" href="https://medium.com/@HBurnOfficial"><img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/MediumLogo.svg" alt="" /></a></li>
              <li><a target="_blank" href="https://x.com/HBurn_Official"><img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/XLogo.svg" alt="" /></a></li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
}

const mapDispatchToProps = { logout, setAddress, getNonce, login, setNonce, getUser };

const mapStateToProps = ({ Auth }) => {
  let { publicAddress, network, nonce, isLogin, isNonce, isChangeNetwork } = Auth;
  return { publicAddress, network, nonce, isLogin, isNonce, isChangeNetwork }
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer);