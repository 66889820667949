/*========== APP ACTIONS ============= */

export const login = (data) => ({
  type: 'LOGIN',
  payload: data,
});

export const logout = (data) => ({
  type: 'LOGOUT',
  payload: data,
});

export const setAddress = (data) => ({
  type: 'SET_ADDRESS',
  payload: data,
});

export const toggleLoader = (data) => ({
  type: 'TOGGLE_LOADER',
  payload: data,
});

export const setLogin = (data) => ({
  type: 'SET_LOGIN',
  payload: data,
});

export const getNonce = (data) => ({
  type: 'GET_NONCE',
  payload: data,
});

export const setNonce = (data) => ({
  type: 'SET_NONCE',
  payload: data,
});


/*========== MARKETPLACE ACTIONS ============= */

export const getLottery = () => ({
  type: 'GET_LOTTERY'
});

export const setLottery = (data) => ({
  type: 'SET_LOTTERY',
  payload: data,
});

export const getUriData = (data) => ({
  type: 'GET_URI_DATA',
  payload: data,
});

export const setUriData = (data) => ({
  type: 'SET_URI_DATA',
  payload: data,
});

/*========== KYB ACTIONS ============= */

export const kybSubmit = (data) => ({
  type: 'KYB_SUBMIT',
  payload: data
});

export const kybResponse = (data) => (console.log("*** data", data), {
  type: 'KYB_RESPONSE',
  payload: data
});

export const getKybStatus = (data) => ({
  type: 'GET_KYB_STATUS',
  payload: data
});

export const setKybStatus = (data) => ({
  type: 'SET_KYB_STATUS',
  payload: data
});

export const setKyb = (data) => ({
  type: 'SET_KYB',
  payload: data
});


export const setInvesterData = (data) => ({
  type: 'SET_INVESTER_DATA',
  payload: data
});

export const getInvesterData = (data) => ({
  type: 'GET_INVESTER_DATA',
  payload: data
});

export const saveInvesterData = (data) => ({
  type: 'SAVE_INVESTER_DATA',
  payload: data
});


export const getPrice = (data) => ({
  type: 'GET_PRICE',
  payload: data
});

export const setPrice = (data) => ({
  type: 'SET_PRICE',
  payload: data
});


export const getSeed = (data) => ({
  type: 'GET_SEED',
  payload: data
});

export const setSeed = (data) => ({
  type: 'SET_SEED',
  payload: data
});

export const saveSeed = (data) => ({
  type: 'SAVE_SEED',
  payload: data
});

export const setPatnerNFT = (data) => ({
  type: 'SET_PATNER_NFT',
  payload: data
});

export const whiteListNFT = (data) => ({
  type: 'WHITELISTED_NFT',
  payload: data
});

export const whiteListPFPNFT = (data) => ({
  type: 'WHITELISTED_PFP_NFT',
  payload: data
});

export const whiteListedSeedPhase = (data) => ({
  type: 'WHITELISTED_SEED_PHASE',
  payload: data
});


/*========== DAO ACTIONS ============= */

export const getDAO = () => ({
  type: 'GET_DAO'
});

export const setDAO = (data) => ({
  type: 'SET_DAO',
  payload: data,
});

export const getProposals = (data) => ({
  type: 'GET_PROPOSALS',
  payload: data,
});

export const setProposals = (data) => ({
  type: 'SET_PROPOSALS',
  payload: data,
});

export const deployDAO = (data) => ({
  type: 'DEPLOY_DAO',
  payload: data,
});

export const addProposal = (data) => ({
  type: 'ADD_PROPOSAL',
  payload: data,
});

export const sendEmailDao = (data) => ({
  type: 'SEND_EMAIL_DAO',
  payload: data,
});


/*========== HBURN DAO ACTIONS ============= */

export const getHBurnDAO = (data) => ({
  type: 'GET_HBURN_DAO',
  payload: data,
});

export const setHBurnDAO = (data) => ({
  type: 'SET_HBURN_DAO',
  payload: data,
});

export const getHBurnProposals = (data) => ({
  type: 'GET_HBURN_PROPOSALS',
  payload: data,
});

export const setHBurnProposals = (data) => ({
  type: 'SET_HBURN_PROPOSALS',
  payload: data,
});

export const addHBurnProposal = (data) => ({
  type: 'ADD_HBURN_PROPOSAL',
  payload: data,
});

export const sendEmailHBurn = (data) => ({
  type: 'SEND_EMAIL_HBURN',
  payload: data,
});

export const uploadCollection = (data) => ({
  type: 'UPLOAD_COLLECTION',
  payload: data
});

export const getERC20 = () => ({
  type: 'GET_ERC20'
});

export const setERC20 = (data) => ({
  type: 'SET_ERC20',
  payload: data,
});

export const getERC721 = () => ({
  type: 'GET_ERC721'
});

export const setERC721 = (data) => ({
  type: 'SET_ERC721',
  payload: data,
});


// marketplace
/*========== CREATE NFT ACTIONS ============= */

export const createNFT = (data) => ({
  type: 'CREATE_NFT',
  payload: data,
});

export const mintNFT = (data) => ({
  type: 'MINT_NFT',
  payload: data,
});

export const getSingleNft = (data) => ({
  type: 'GET_SINGLE_NFT',
  payload: data,
});

export const setSingleNft = (data) => ({
  type: 'SET_SINGLE_NFT',
  payload: data,
});

export const mintNFT721 = (data) => ({
  type: 'MINT_NFT_721',
  payload: data,
});

export const mintNFT1155 = (data) => ({
  type: 'MINT_NFT_1155',
  payload: data,
});

export const listNft = (data) => ({
  type: 'LIST_NFT',
  payload: data,
});

export const unListNft = (data) => ({
  type: 'UNLIST_NFT',
  payload: data,
});

export const transferNft = (data) => ({
  type: 'TRANSFER_NFT',
  payload: data,
});

export const createAuction = (data) => ({
  type: 'CREATE_AUCTION',
  payload: data,
});

export const placeBid = (data) => ({
  type: 'PLACE_BID',
  payload: data,
});

export const endAuction = (data) => ({
  type: 'END_AUCTION',
  payload: data,
});

export const getBids = (data) => ({
  type: 'GET_BIDS',
  payload: data,
});

export const setBids = (data) => ({
  type: 'SET_BIDS',
  payload: data,
});

export const getAuctions = (data) => ({
  type: 'GET_AUCTIONS',
  payload: data,
});

export const setAuctions = (data) => ({
  type: 'SET_AUCTIONS',
  payload: data,
});

export const getAllNfts = (data) => ({
  type: 'GET_ALL_NFTS',
  payload: data,
});

export const setAllNfts = (data) => ({
  type: 'SET_ALL_NFTS',
  payload: data,
});


export const setNetwork = (data) => ({
  type: 'SET_NETWORK',
  payload: data
})

export const setChangeNetwork = (data) => ({
  type: 'SET_CHANGE_NETWORK',
  payload: data
})

export const getUser = (data) => ({
  type: 'GET_USER',
  payload: data
});

export const updateUser = (data) => ({
  type: 'UPDATE_USER',
  payload: data
});

export const updateUserImage = (data) => ({
  type: 'UPDATE_USER_IMAGE',
  payload: data
});

export const setUser = (data) => ({
  type: 'SET_USER',
  payload: data
})

export const getVikingNFT = (data) => ({
  type: 'GET_VIKING_NFT',
  payload: data
})

export const setVikingNFT = (data) => ({
  type: 'SET_VIKING_NFT',
  payload: data
})

export const getPartnerNFT = (data) => ({
  type: 'GET_PARTNER_NFT',
  payload: data
})

export const setPartnerNFT = (data) => ({
  type: 'SET_PARTNER_NFT',
  payload: data
})

/*========== CREATE Collection ACTIONS ============= */

export const createCollection = (data) => ({
  type: 'CREATE_COLLECTION',
  payload: data,
});

export const getCollection = () => ({
  type: 'GET_COLLECTION'
});

export const setCollection = (data) => ({
  type: 'SET_COLLECTION',
  payload: data,
});

export const getSingleCollection = (data) => ({
  type: 'GET_SINGLE_COLLECTION',
  payload: data,
});

export const setSingleCollection = (data) => ({
  type: 'SET_SINGLE_COLLECTION',
  payload: data,
});

export const getCollectionDetails = (data) => ({
  type: 'GET_SINGLE_COLLECTION_DETAILS',
  payload: data,
});

export const setSingleCollectionDetails = (data) => ({
  type: 'SET_SINGLE_COLLECTION_DETAILS',
  payload: data,
});

export const getAllCollection = (data) => ({
  type: 'GET_ALL_COLLECTION',
  payload: data,
});

export const setAllCollection = (data) => ({
  type: 'SET_ALL_COLLECTION',
  payload: data,
});

export const getExplore = (data) => ({
  type: 'GET_EXPLORE',
  payload: data,
});

export const setExplore = (data) => ({
  type: 'SET_EXPLORE',
  payload: data,
});

export const getUserNft = (data) => ({
  type: 'GET_USER_NFTS',
  payload: data,
});

export const setUserNft = (data) => ({
  type: 'SET_USER_NFTS',
  payload: data,
});

export const addRemoveFavItems = (data) => ({
  type: 'ADD_REMOVE_FAV_ITEMS',
  payload: data,
});

export const getUserFavItems = (data) => ({
  type: 'GET_USER_FAV_ITEMS',
  payload: data,
});

export const setUserFavItems = (data) => ({
  type: 'SET_USER_FAV_ITEMS',
  payload: data,
});

export const updateLogo = (data) => ({
  type: 'UPLOAD_LOGO',
  payload: data,
});

export const updateBackground = (data) => ({
  type: 'UPLOAD_BACKGROUND',
  payload: data,
});

export const updateCollection = (data) => ({
  type: 'UPDATE_COLLECTION',
  payload: data,
});

/*========== ACTIVITY ACTIONS ============= */

export const getAllActivity = (data) => ({
  type: 'GET_ALL_ACTIVITY',
  payload: data,
});

export const setAllActivity = (data) => ({
  type: 'SET_ALL_ACTIVITY',
  payload: data,
});

export const getNftActivity = (data) => ({
  type: 'GET_NFT_ACTIVITY',
  payload: data,
});

export const setNftActivity = (data) => ({
  type: 'SET_NFT_ACTIVITY',
  payload: data,
});

export const getCategory = () => ({
  type: 'GET_CATEGORY',
});

export const setCategory = (data) => ({
  type: 'SET_CATEGORY',
  payload: data,
});

export const getLeaderboard = () => ({
  type: 'GET_LEADERBOARD',
});

export const setLeaderboard = (data) => ({
  type: 'SET_LEADERBOARD',
  payload: data,
});